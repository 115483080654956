import { Dropdown, Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';

export const UserInitials = styled(Dropdown.Toggle)`
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border:none;
  
  &::after {
    display: none;
  }
`;

export const StyledNavbar = styled(Navbar)`
  background: ${({ theme }) => theme.navbarBackground} !important;
  border-bottom: solid 1px ${({ theme }) => theme.border};
  padding-left: 15px;
  padding-right: 15px;
`;

export const StyledNav = styled(Nav)`
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
`;

export const StyledNavLink = styled(Nav.Link)`
  display: inline-block;
  color: ${({ theme }) => theme.text} !important;
  padding: ${({ theme }) => theme.padding};
  margin: 5px;
  text-align: center;
  font-size

  &:hover {
    color: ${({ theme }) => theme.text} !important;
  }
`;