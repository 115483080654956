import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import HomePage from './pages/HomePage';
import SettingsPage from './pages/SettingsPage';
import NavBar from './components/NavBar';
import { Container } from 'react-bootstrap';
import { useTheme } from './context/ThemeContext';
import UserForm from './components/settings/UserForm';

function App() {
  const { theme } = useTheme();
  return (
    <Container fluid style={{ padding: 0, margin: 0, background: theme.background }}>
      <div className="App">
        <header className="App-header">
          <Router>
            <NavBar />
            <Routes>
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<HomePage />}></Route>
                <Route path="/home" element={<HomePage />} />
                <Route path="/settings" element={<SettingsPage />} />
                <Route path="/settings/manage-user" element={<SettingsPage pageId='manage-user' />} />
                <Route path="/settings/create-user" element={<SettingsPage pageId='create-user' />} />
              </Route>
            </Routes>
          </Router>
        </header>
      </div>
    </Container>
  );
}

export default App;
