import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

export const themes = {
  dark: {
    navbarBackground: 'linear-gradient(80deg, rgba(52,58,64,1) 40%, rgba(4,4,4,1) 60%);',
    skeletonBackground: 'linear-gradient(90deg, rgba(4,4,4,1) 1%, rgba(52,58,64,1) 40%, rgba(4,4,4,1) 60%)',
    selectValueBackground: 'linear-gradient(80deg, rgba(4,4,4,1) -50%, rgba(52,58,64,1) 70%)',
    background: '#343a40',
    text: '#ffffff',
    primary: '#007bff',
    secondary: '#6c757d',
    success: '#28a745',
    warning: '#ffc107',
    hover: '#495057',
    border: '#6a6868',
    borderRadius: '10px',
    padding: '5px 15px',
  },
  light: {
    navbarBackground: 'linear-gradient(80deg, rgba(52,58,64,1) 20%, rgba(222,222,222,1) 80%)',
    skeletonBackground: 'linear-gradient(90deg, rgba(222,222,222,1) 1%, rgba(52,58,64,1) 40%, rgba(222,222,222,1) 60%)',
    selectValueBackground: 'linear-gradient(80deg, rgba(52,58,64,1) -50%, rgba(222,222,222,1) 70%)',
    background: '#DEDEDE',
    text: '#000000',
    primary: '#007bff',
    secondary: '#6c757d',
    success: '#28a745',
    warning: '#ffc107',
    hover: '#e9ecef',
    border: '#a4a6a8',
    borderRadius: '10px',
    padding: '5px 15px',
  }
};

type ThemeContextType = {
  theme: typeof themes.dark;
  setTheme: (theme: string) => void;
};

const getSystemTheme = (): 'dark' | 'light' => {
  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return 'dark';
  }
  return 'light';
};

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeContextProvider = ({ children }: { children: ReactNode }) => {
  const [theme, setThemeState] = useState(themes.dark);

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme) {
      if ((storedTheme === 'dark' || storedTheme === 'light')) {
        setThemeState(themes[storedTheme]);
      }
      else {
        setThemeState(themes[getSystemTheme()]);
      }
    }
  }, []);

  const setTheme = (theme: string) => {
    if ((theme === 'dark' || theme === 'light')) {
      setThemeState(themes[theme]);
    }
    else {
      setThemeState(themes[getSystemTheme()]);
    }
    localStorage.setItem('theme', theme);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = React.useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeContextProvider');
  }
  return context;
};
