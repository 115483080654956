import toast from 'react-hot-toast';

interface ToastOptions {
  type: 'success' | 'error' | 'warning' | 'info';
  message: string;
}

const showToast = ({ type, message }: ToastOptions) => {
  switch (type) {
    case 'success':
      toast.success(message);
      break;
    case 'error':
      toast.error(message);
      break;
    case 'warning':
      toast(message, { icon: '⚠️' });
      break;
    case 'info':
      toast(message, { icon: 'ℹ️' });
      break;
    default:
      toast(message);
      break;
  }
};

export default showToast;
