import { Table } from "react-bootstrap";
import { css, styled } from "styled-components";
import { Form } from 'react-bootstrap';

export const StyledTable = styled(Table)`
  border-color: ${({ theme }) => theme.border} !important;
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.background};

  th, td {
    border-color: ${({ theme }) => theme.border} !important;
  }

  th {
    background-color: ${({ theme }) => theme.hover};
    color: ${({ theme }) => theme.text};
  }

  tr:hover {
    background-color: ${({ theme }) => theme.hover} !important;
  }
`;

interface StyledButtonProps {
  variant: 'primary' | 'secondary' | 'success' | 'warning';
}

const buttonStyles = (variant: 'primary' | 'secondary' | 'success' | 'warning', theme: any) => {
  switch (variant) {
    case 'primary':
      return css`
        background-color: ${theme.primary};
        color: ${theme.text};
        &:hover {
          background-color: ${theme.hover};
        }
      `;
    case 'secondary':
      return css`
        background-color: ${theme.secondary};
        color: ${theme.text};
        &:hover {
          background-color: ${theme.hover};
        }
      `;
    case 'success':
      return css`
        background-color: ${theme.success};
        color: ${theme.text};
        &:hover {
          background-color: ${theme.hover};
        }
      `;
    case 'warning':
      return css`
        background-color: ${theme.warning};
        color: ${theme.text === '#ffffff' ? '#000000' : '#ffffff'};
        &:hover {
          background-color: ${theme.hover};
        }
      `;
    default:
      return css`
        background-color: ${theme.primary};
        color: ${theme.text};
        &:hover {
          background-color: ${theme.hover};
        }
      `;
  }
};

export const StyledButton = styled.button<StyledButtonProps>`
  padding: ${({ theme }) => theme.padding};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius};
  font-size: 16px;
  cursor: pointer;

  ${({ variant, theme }) => buttonStyles(variant, theme)}
`;


export const StyledFormControl = styled(Form.Control)`
  background-color: ${({ theme }) => theme.background} !important;
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.border};
  border-bottom: 2px solid ${({ theme }) => theme.border};
  outline: none;

  &::placeholder{
    color: ${({ theme }) => theme.text};
  }

  &:focus {
    background-color: ${({ theme }) => theme.hover};
    border-bottom: 2px solid ${({ theme }) => theme.warning};
    color: ${({ theme }) => theme.text};
  }
`;

export const StyledSelect = styled(Form.Select)`
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.border};
  border-bottom: 2px solid ${({ theme }) => theme.border};
  outline: none;

  &:focus {
    background-color: ${({ theme }) => theme.hover};
    color: ${({ theme }) => theme.text};
    border-bottom: 2px solid ${({ theme }) => theme.warning};
  }
`;
