import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SettingsSidebar from '../components/settings/SettingsSideBar.';
import ListUsers from '../components/settings/ListUsers';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import UserProfile from '../components/settings/UserProfile';
import UserForm from '../components/settings/UserForm';

interface SettingsPageProps {
    pageId?: string;
}

const SettingsPage: React.FC<SettingsPageProps> = ({ pageId }) => {

    const renderComponentByPageId = () => {
        switch (pageId) {
            case 'manage-user':
                return <ListUsers />;
            case 'create-user':
                return <UserForm />;
            default:
                return <UserProfile />;
        }
    }

    return (
        <Container fluid>
            <Row>
                <Col xs={12} md={2}>
                    <SettingsSidebar />
                </Col>
                <Col xs={12} md={10} className="d-flex flex-column">
                    {renderComponentByPageId()}
                </Col>
            </Row>
        </Container>
    );
};

export default SettingsPage;