import { Nav } from 'react-bootstrap';
import styled from 'styled-components';

export const SidebarContainer = styled.div`
  height: 100vh; /* Full viewport height */
  color: ${({ theme }) => theme.text} !important;
  background-color: ${({ theme }) => theme.background};
  overflow: hidden;
  padding-top: 56px;
  display: flex;
  flex-direction: column;
  border-right: solid 1px ${({ theme }) => theme.border};
`;

export const SidebarItem = styled(Nav.Link)`
  color: ${({ theme }) => theme.text} !important;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  width:100%;

  &:hover {
    background-color: ${({ theme }) => theme.hover} !important;
  }

  svg {
    margin-right: 10px;
  }
`;