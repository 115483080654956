import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import Keycloak, { KeycloakInstance } from 'keycloak-js';
import useFetchUserGroups from '../hooks/keycloak/useFetchGroups';
import axios from 'axios';
import { createApiInstance, handleResponse } from '../services/api/api';

interface AuthContextProps {
  keycloak?: KeycloakInstance;
  isAuthenticated: boolean;
  userGroups: any[];
  login: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [keycloak, setKeycloak] = useState<KeycloakInstance | undefined>(undefined);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false); // Flag to ensure initialization occurs only once
  const [userGroups, setUserGroups] = useState<any[]>([]);

  useEffect(() => {
    if (!isInitialized) {
      const keyCloakConfig = {
        url: process.env.REACT_APP_KEYCLOAK_API_BASE_URL,
        realm: process.env.REACT_APP_KEYCLOAK_REALM,
        clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
      };
      const keycloakInstance = new Keycloak(keyCloakConfig);

      keycloakInstance
        .init({ onLoad: 'login-required', checkLoginIframe: false, redirectUri: process.env.REACT_APP_KEYCLOAK_REDIRECT_URL })
        .then((authenticated) => {
          setKeycloak(keycloakInstance);
          setIsAuthenticated(authenticated);
          setIsInitialized(true); // Set initialization flag
        })
        .catch((err) => {
          console.error('Keycloak initialization error:', err);
        });
    }
  }, [isInitialized]); // Dependency ensures this runs only when `isInitialized` is false

  useEffect(() => {
    if (keycloak) {
      const initKeycloak = async () => {
        //when user is authenticated get required details and refresh token after every interval.
        if (keycloak.authenticated) {
          //refresh token after every interval
          setInterval(async () => {
            await refreshToken();
          }, process.env.REACT_APP_KEYCLOAK_REFRESH_TOKEN_INTERVAL);

          //get groups of user.
          const groups = await fetchUserGroups();
          if (groups) {
            setUserGroups(groups);
          }

          //get more details when needed.
        }
      }

      initKeycloak();
    }
  }, [keycloak]);

  const login = () => {
    keycloak?.login();
  };

  const logout = () => {
    keycloak?.logout();
  };

  const refreshToken = async () => {
    try {
      const refreshed = await keycloak?.updateToken(30); // Refresh if token expires in less than 30 seconds
      // if (refreshed) {
      //   console.log('Token refreshed');
      // } else {
      //   console.log('Token not refreshed, valid for', Math.ceil(keycloak?.tokenParsed?.exp! - new Date().getTime() / 1000), 'seconds');
      // }
    } catch (error) {
      //console.error('Failed to refresh token:', error);
    }
  };

  const fetchUserGroups = async () => {
    try {
      const api = createApiInstance(process.env.REACT_APP_KEYCLOAK_API_BASE_URL, keycloak?.token);

      // Fetch user groups
      const response = await api.get<any[]>(`/admin/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/users/${keycloak?.idTokenParsed?.sub}/groups`);
      const apiResponse = handleResponse(response);

      return apiResponse.data;
    } catch (err) {
      console.log('Error fetching user groups');
    }
  };

  return (
    <AuthContext.Provider value={{ keycloak, isAuthenticated, login, logout, userGroups }}>
      {isInitialized ? children : <p>Loading...</p>} {/* Show a loader until initialized */}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
