import React from 'react';
import { Nav } from 'react-bootstrap';
import { FaHome, FaUser, FaCog, FaInfoCircle, FaUsers } from 'react-icons/fa';
import { SidebarContainer, SidebarItem } from '../styled/SideBarStyled';
import { LinkContainer } from 'react-router-bootstrap';
import { useAuth } from '../../context/AuthContext';

const SettingsSidebar: React.FC = () => {
    const { userGroups } = useAuth();

    const getItemsRoleBased = () => {
        const sideBarItems = [];
        sideBarItems.push((<LinkContainer key={'settings'} to="/settings">
            <SidebarItem>
                <FaUser /> My Profile
            </SidebarItem>
        </LinkContainer>));

        //validate based on user group.
        if (userGroups) {
            if (userGroups.find(x => x.name === "SuperAdmin")) {
                sideBarItems.push((<LinkContainer key={'manageUser'} to="/settings/manage-user">
                    <SidebarItem>
                        <FaUsers /> User Management
                    </SidebarItem>
                </LinkContainer>));
            }
        }

        return sideBarItems;
    }

    return (
        <SidebarContainer>
            <Nav>
                {getItemsRoleBased().map(x => x)}
            </Nav>
        </SidebarContainer>
    );
};

export default SettingsSidebar;
