import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Container, Row, Col } from 'react-bootstrap';
import { useTheme } from '../../context/ThemeContext';

interface SkeletonProps {
    count?: number;
    height?: number[];
    width?: string[];
    rows?: number;
}

const SkeletonLoading: React.FC<SkeletonProps> = ({
    count = 1,
    height = [25],
    width = ['100%'],
    rows = 1,
}) => {
    const { theme } = useTheme();
    const skeletonRows = Array(rows).fill(null);

    return (
        <SkeletonTheme baseColor={theme.background} highlightColor={theme.hover} borderRadius="0px">
            <style>
                {`
          .react-loading-skeleton {
            background: ${theme.skeletonBackground};
          }
        `}
            </style>
            <Container>
                {skeletonRows.map((_, index) => (
                    <Row key={index} className="mb-2">
                        <Col>
                            <Skeleton count={count} height={height[index]} width={width[index] ?? '100%'} />
                        </Col>
                    </Row>
                ))}
            </Container>
        </SkeletonTheme>
    );
};

export default SkeletonLoading;
